import axios from 'axios'

const checkApiConnection = async () => {
    return fetch(`${process.env.REACT_APP_API_URL}status`, {
        mode: 'no-cors',
    })
        .then(() => { return true })
        .catch(() => { return false })
};

export const registerUser = async (data) => {
    if (!!await checkApiConnection()) {
        return await axios.post(
            `${process.env.REACT_APP_API_URL}auth/register`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                return response.data
            })
            .catch(error => {
                return error.response.data
            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};

export const loginUser = async (data) => {
    if (!!await checkApiConnection()) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}auth/login`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${data.token}`
                }
            }
        )
            .then(response => {
                return response.data
            })
            .catch(error => {
                return error.response.data

            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};

export const requestPasswordReset = async (data) => {
    if (!!await checkApiConnection()) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}auth/password-recovery`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                return response.data
            })
            .catch(error => {
                return error.response.data

            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};

export const resetUserPassword = async (data) => {
    if (!!await checkApiConnection()) {
        return await axios.post(
            `${process.env.REACT_APP_API_URL}auth/password-reset`,
            { ...data },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                return response.data
            })
            .catch(error => {
                return error.response.data
            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};

export const updateUser = async (data) => {
    if (!!await checkApiConnection()) {
        return await axios.put(
            `${process.env.REACT_APP_API_URL}user/locale`,
            { ...data },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${data.payload.token}`
                }
            }
        )
            .then(response => {
                return response.data
            })
            .catch(error => {
                return error.response.data
            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};

export const getAllUsers = async (data) => {
    if (!!await checkApiConnection() && data.token) {
        return await axios.get(
            `${process.env.REACT_APP_API_URL}user`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${data.token}`
                }
            }
        )
            .then(response => {
                console.log('api users', response.data.data);
                return response.data.data
            })
            .catch((error) => {
                console.log(error);
                return {error: true, status: 401, message: "Unauthorized"}
            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};

export const updateUserStatus = async (data) => {
    console.log('API: update user', data);
    if (!!await checkApiConnection()) {
        return await axios.put(
            `${process.env.REACT_APP_API_URL}user/update-status`,
            { ...data },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${data.payload.token}`
                }
            }
        )
            .then(response => {
                console.log('api response', response);
                return response.data
            })
            .catch(error => {
                console.error('api error: ', error);
                return error.response.data
            })
    } else {
        return {error: true, status: 503, messageCode: "customStatus.503.1"}
    }
};
