import React, {useEffect} from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { 
  loginUser,
  clearState, 
} from '../../redux/auth/actions'
import { selectAuthMessageCode } from '../../redux/auth/selectors'
import { selectLocale } from '../../redux/global/selectors'
import { getTranslation } from "../../utils/i18n"

const LoginPage = ({ 
  messageCode, 
  onHandleLogin, 
  loginUser, 
  locale,
  clearState,
}) => {
  useEffect(() => {
    return () => {
      clearState()
    }
    // eslint-disable-next-line
  }, []);

  onHandleLogin = (event) => {
    event.preventDefault();

    let email = event.target.email.value;
    let password = event.target.password.value;

    const data = {
      email,
      password,
      locale
    };
    loginUser(data)
  };
  return (
    <div className="divStyle">
      {messageCode &&
      <div className="alert alert-danger" role="alert"
           dangerouslySetInnerHTML={{__html: getTranslation(`auth.${messageCode}`, locale)}}>
      </div>}

      <h3 className="bottomMargin1">{getTranslation("auth.login.title", locale)}</h3>
      <form onSubmit={onHandleLogin}>
        <div className="form-group row">
          <label htmlFor="email" className="col-sm-2 col-form-label">{getTranslation("auth.global.email", locale)}</label>
          <div className="col-sm-10">
            <input className="form-control" type="email" name="email" required/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="password" className="col-sm-2 col-form-label">{getTranslation("auth.global.password", locale)}</label>
          <div className="col-sm-10">
            <input className="form-control" type="password" name="password" required/>
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary mb-2 topMargin1">{getTranslation("auth.login.login", locale)}</button>
        </div>
      </form>

      {/*<div>
        {getTranslation("auth.login.forgotPassword", locale)}
        <Link to="/password-reset">{getTranslation("auth.login.requestPasswordReset", locale)}</Link>
      </div>
      <div>
        {getTranslation("auth.login.noAccount", locale)}
        <Link to="/register">{getTranslation("auth.login.registerHere", locale)}</Link>
      </div>*/}
    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  messageCode: selectAuthMessageCode,
  locale: selectLocale,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (user) => dispatch(loginUser(user)),
  clearState: () => dispatch(clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
