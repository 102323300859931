export const GET_USERS_REQUESTED = '@admin/GET_USERS_REQUESTED'
export const FILTER_USERS = '@admin/FILTER_USERS'
export const SET_LOADING_USERS = '@admin/SET_LOADING_USERS'
export const GET_USERS_SUCCESS = '@admin/GET_USERS_SUCCESS'
export const UPDATE_USER_IS_ACTIVE_REQUEST  = '@admin/UPDATE_USER_IS_ACTIVE_REQUEST'
export const SET_UPDATING_ACTIVE_STATUS  = '@admin/SET_UPDATING_ACTIVE_STATUS'
export const CLEAR_USERS_FROM_STATE = '@admin/CLEAR_USERS_FROM_STATE'


export const getUsers = (token) => ({ 
    type: GET_USERS_REQUESTED,
    payload: {token},
  })

  export const filterUsers = (filterValue) => ({ 
    type: FILTER_USERS,
    payload: {filterValue},
  })

  export const updateIsActive = (token, isActive, id) => ({ 
    type: UPDATE_USER_IS_ACTIVE_REQUEST,
    payload: {token, isActive, id},
  })

  export const clearUsers = () => ({
    type: CLEAR_USERS_FROM_STATE
  })
