import { 
  put, 
  call, 
  takeEvery, 
  all, 
  fork } from 'redux-saga/effects'
import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    REQUEST_RESET_PASSWORD_REQUEST,
    PASSWORD_RESET_REQUEST_SUCCESS,
    PASSWORD_RESET_REQUEST_FAILED,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_FAILED
} from './actions'
import history from '../../utils/history'
import {
  registerUser,
  loginUser,
  resetUserPassword,
  requestPasswordReset,
} from '../api/api'

function* registerUserRequested(action) {
  const response = yield call(registerUser, action.payload);
  if (!!response.error) {
    yield put({ type: REGISTER_USER_FAILED, payload: response });
    history.push('/register')
  }
  else {
    yield put({ type: REGISTER_USER_SUCCESS, payload: '' });
    history.push('/login')
  }
}

function* loginUserRequested(action) {
  const response = yield call(loginUser, action.payload);
  if (!!response.error) {
    yield put({ type: LOGIN_USER_FAILED, payload: response });
    history.push('/login')
  } else {
      setUserLocalStorage(response.data);
      yield put({type: LOGIN_USER_SUCCESS, payload: response.data});
      history.push('/dashboard')
  }

  function setUserLocalStorage(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('username', data.username);
    localStorage.setItem('isAdmin', data.isAdmin);
    localStorage.setItem('isActive', data.isActive);
    localStorage.setItem('locale', data.locale);
  }
}

function* requestPasswordResetRequested(action) {
  const response = yield call(requestPasswordReset, action.payload);
  if (!!response.error) {
    yield put({ type: PASSWORD_RESET_REQUEST_FAILED, payload: response })
  } else {
    yield put({type: PASSWORD_RESET_REQUEST_SUCCESS, payload: response.data});
    history.push('/login')
  }
}

function* resetPasswordRequested(action) {
  const response = yield call(resetUserPassword, action.payload);
  if (!!response.error) {
    yield put({type: RESET_PASSWORD_FAILED, payload: response.data})
  } else {
    history.push('/login')
  }
}

function* registerUserSaga() {
  yield takeEvery(REGISTER_USER_REQUEST, registerUserRequested)
}

function* loginUserSaga() {
  yield takeEvery(LOGIN_USER_REQUEST, loginUserRequested)
}

function* resetPasswordSaga() {
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordRequested)
}

function* requestPasswordResetSaga() {
    yield takeEvery(REQUEST_RESET_PASSWORD_REQUEST, requestPasswordResetRequested)
}

function* authSaga() {
  yield all([
    fork(registerUserSaga),
    fork(loginUserSaga),
    fork(resetPasswordSaga),
    fork(requestPasswordResetSaga),
  ])
}

export default authSaga
