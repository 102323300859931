import { all, fork } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import authSaga from "./auth/sagas";
import authReducer from "./auth/reducer";
import globalReducer from "./global/reducer";
import globalSaga from "./global/sagas";
import adminReducer from './admin/reducer'
import adminSaga from './admin/sagas';

export const createRootReducer = () =>
  combineReducers({
      auth: authReducer,
      global: globalReducer,
      admin: adminReducer,
  })

export function* rootSaga() {
  yield all([
      fork(authSaga),
      fork(globalSaga),
      fork(adminSaga),
  ])
}
