export const REGISTER_USER_REQUEST = '@auth/REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = '@auth/REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = '@auth/REGISTER_USER_FAILED';


export const LOGIN_USER_REQUEST = '@auth/LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = '@auth/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = '@auth/LOGIN_USER_FAILED';

export const UPDATE_USER_STATUS = '@auth/UPDATE_USER_STATUS';

export const RESET_PASSWORD_REQUEST = '@auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_FAILED = '@auth/RESET_PASSWORD_REQUEST';

export const REQUEST_RESET_PASSWORD_REQUEST = '@auth/REQUEST_RESET_PASSWORD_REQUEST';
export const PASSWORD_RESET_REQUEST_SUCCESS = '@auth/PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_FAILED = '@auth/PASSWORD_RESET_REQUEST_FAILED';
export const CLEAR_ERRORS_FROM_AUTH_STATE = '@auth/CLEAR_ERRORS_FROM_AUTH_STATE';


export const registerUser = (user) => ({ 
  type: REGISTER_USER_REQUEST,
  payload: user,
});

export const loginUser = (user) => ({ 
  type: LOGIN_USER_REQUEST,
  payload: user,
});

export const requestPasswordReset = (data) => ({ 
  type: REQUEST_RESET_PASSWORD_REQUEST,
  payload: data
});

export const updateUserStatus = (token) => ({
  type: UPDATE_USER_STATUS,
  payload: token
});

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload
});

export const clearState = (payload) => ({
  type: CLEAR_ERRORS_FROM_AUTH_STATE,
  payload
});
