import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectLocale } from '../../redux/global/selectors'
import { getTranslation } from "../../utils/i18n"

const PageNotFound = ({
  locale, 
}) => {
  return (
    <div>
      <h2 className="list-head">{getTranslation("error.404.title", locale)}</h2>
      <div>{getTranslation("error.404.message", locale)}</div>      
    </div>
  )
}

const mapStateToProps  = createStructuredSelector({
  locale: selectLocale,
})

export default connect(mapStateToProps)(PageNotFound)
