
import React from "react";
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'

import history from '../utils/history'
import store from '../redux/store'
import './App.css'

import Navbar from '../containers/global/navbar'
// import Footer from '../containers/global/footer'
// import RegisterPage from '../containers/auth/register'
// import ChangePasswordPage from '../containers/auth/changePassword'
import PageNotFound from '../containers/global/404page'
import LoginForm from '../containers/auth/login'
// import AdminPage from '../containers/admin/admin'
// import PasswordRequestPage from '../containers/auth/passwordRequest'
import BlankPage from '../containers/global/blanc'

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Navbar />
        <div className="container main-content">
          <Switch>
            <Route path="/" exact component={BlankPage} />
            
            {/*<Route path="/register" exact component={RegisterPage} />*/}
            <Route path="/login" exact component={LoginForm} />
            {/*<Route path="/password-reset" exact component={PasswordRequestPage} />*/}
            {/*<Route path="/password-reset/:notificationId" exact component={ChangePasswordPage} />*/}

            {/*<Route path="/admin" exact component={AdminPage} />*/}

            <Route path="/404" exact component={PageNotFound} />
            <Route path="/:non-existing" exact component={PageNotFound} />
            <Route component={() => <Redirect to="/404" />} />
          </Switch>
        </div>
        {/*<Footer />*/}
      </Router>
    </Provider>
  )
}
export default App
