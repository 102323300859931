import {
  SET_LOADING_USERS,
  GET_USERS_SUCCESS,
  FILTER_USERS,
  CLEAR_USERS_FROM_STATE,
} from './actions'

const initialState = {
  loading: false,
  users: [],
}

export default function adminReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOADING_USERS:
      return {
        ...state,
        loading: true,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
        loading: false,
      }
    case CLEAR_USERS_FROM_STATE:
      return initialState
    case FILTER_USERS:
      return {
        ...state,
        filterValue: payload,
      }
    // Return default state if you didn't match any case
    default:
      return state
  }
}
