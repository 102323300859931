import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  UPDATE_USER_STATUS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  RESET_PASSWORD_FAILED,
  CLEAR_ERRORS_FROM_AUTH_STATE,
} from './actions'

// Define your state here
const initialState = {
  user: {
    username: '',
    token: '',
    isAdmin: false,
    isActive: false,
  },
  error: false,
  messageCode: '',
  loading: false,
}

// This export default will control your state for your application
export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true, 
      }
      case REGISTER_USER_SUCCESS:
        return {
          ...state,
          loading: false, 
        }
    case REGISTER_USER_FAILED:
      return {
        ...state,
        ...payload,
        loading: false, 
      }
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true, 
      }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...payload,
        },
        loading: false,
      }
    case LOGIN_USER_FAILED:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case UPDATE_USER_STATUS:
      return {
        user: {
          username: '',
          token: '',
          isAdmin: false,
          isActive: false,
        },
        error: false,
        messageCode: '',
        loading: false,
      }
    case CLEAR_ERRORS_FROM_AUTH_STATE:
      return {
        ...state,
        error: false,
        messageCode: '',
      }
    default:
      return state
  }
}
