
import en from './en'
import nl from './nl'
import fr from './fr'

export const getTranslation = (key, locale) => {
    const l = locale || getLocale()
    let keys;
    switch (l) {
        case 'nl':
            keys = nl
            break
        case 'fr':
            keys = fr
            break
        default:
            keys = en
    }
    return keys[key]
}

export const getLocale = () => {
    let l;
    if (navigator.languages && navigator.languages.length > 0) {
        l = navigator.languages[0]
    } else {
        l = navigator.language && navigator.language
    }
    return l ? l.slice(0,2) : "en"
}