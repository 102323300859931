import { put, call, takeEvery, all, fork } from 'redux-saga/effects'
import {
    SET_LOADING_USERS,
    GET_USERS_REQUESTED,
    GET_USERS_SUCCESS,
    UPDATE_USER_IS_ACTIVE_REQUEST,
    SET_UPDATING_ACTIVE_STATUS,
  } from './actions'
import history from '../../utils/history'
import {
    getAllUsers,
    updateUserStatus,
  } from '../api/api'

  function* getUsers(action) {
    yield put({ type: SET_LOADING_USERS })
    console.log('SAGA: called getUsers with: ', action.payload)
    const users = yield call(getAllUsers, action.payload)
    console.log('admin saga user', users)
    if (!!users.error) {
      history.push('/admin')
    } else {
      yield put({ type: GET_USERS_SUCCESS, payload: users })
    }
  }

  function* updateUserActiveStatus(action) {
    yield put({type: SET_UPDATING_ACTIVE_STATUS})
    console.log('SAGA: called updateUserActiveStatus with: ', action.payload)
    const response = yield call(updateUserStatus, action)
    console.log('SAGA: updateUserActiveStatus response: ', response)
    history.push('/admin')
  }

  function* updateUserActiveSaga() {
    yield takeEvery(UPDATE_USER_IS_ACTIVE_REQUEST, updateUserActiveStatus)
  }

  function* allUsersSaga() {
    yield takeEvery(GET_USERS_REQUESTED, getUsers)
  }

  function* adminSaga() {
    yield all([
      fork(allUsersSaga),
      fork(updateUserActiveSaga),
    ])
  }
  
  export default adminSaga