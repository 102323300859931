import { call, takeEvery, all, fork } from 'redux-saga/effects'
import { SET_LOCALE } from './actions'
import { updateUser } from '../api/api'

function* updateUserLocale(action) {
  yield call(updateUser, {
    ...action,
    payload: {
      ...action.payload,
      token: localStorage.getItem('token')
    }
  })
}

function* updateUserLocaleSaga() {
  yield takeEvery(SET_LOCALE, updateUserLocale)
}

function* globalSaga() {
  yield all([
    fork(updateUserLocaleSaga),
  ])
}

export default globalSaga
