/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import logo from '../../logo.jpg'
import { NavLink } from 'react-router-dom'
import { updateUserStatus } from '../../redux/auth/actions'
import { getTranslation } from '../../utils/i18n'
import { setLocale } from '../../redux/global/actions'
import { selectUser } from '../../redux/auth/selectors'
import { selectLocale } from '../../redux/global/selectors'


const Navbar = ({
  updateUserStatus,
  locale,
  setLocale
}) => {

  function handleLocaleChange(event) {
    localStorage.setItem('locale', event.target.value);
    setLocale(event.target.value,localStorage.getItem('token') );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light c-navbar">
      <div className="container">
        <div className="navbar-brand">
          {/*<img src={logo} alt="" width="108" height="108" />*/}
          STAMEN KOLAROV &#123; web development &#125;
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {
              localStorage.getItem("isAdmin") === "true" &&
              <li className="nav-item">
                {/*eslint-disable-next-line*/}
                <NavLink className="nav-link" exact to="/admin" activeClassName="nav-link">
                  Admin
                </NavLink>
              </li>
            }
          {
            !localStorage.getItem('token')
              ? <ul className="navbar-nav">
                  {/*<li className="nav-item">*/}
                  {/*  /!*eslint-disable-next-line*!/*/}
                  {/*  <NavLink className="nav-link" exact to="/login" activeClassName="nav-link">*/}
                  {/*    {getTranslation("auth.login.login", locale)}*/}
                  {/*  </NavLink>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  /!*eslint-disable-next-line*!/*/}
                  {/*  <NavLink className="nav-link" exact to="/register" activeClassName="nav-link" >*/}
                  {/*  {getTranslation("auth.register.register", locale)}*/}
                  {/*  </NavLink>*/}
                  {/*</li>*/}
                </ul>
              : <ul className="navbar-nav">
                  <li className="nav-item">
                    {/*eslint-disable-next-line*/}
                    <NavLink className="nav-link" exact to="/login" activeClassName="nav-link" onClick={
                      () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('username');
                        localStorage.removeItem('isAdmin');
                        localStorage.removeItem('isActive');
                        localStorage.removeItem('locale');
                        updateUserStatus()
                      }
                    }>
                      {getTranslation("auth.logout.logout", locale)}
                    </NavLink>
                  </li>
                </ul>
          }
          </ul>
          <div className="m-1">
            {localStorage.getItem('username') && localStorage.getItem('username')}
          </div>
          {/*<select value={locale} onChange={handleLocaleChange} className="custom-select">*/}
          {/*  <option value="en">en</option>*/}
          {/*  <option value="nl">nl</option>*/}
          {/*  <option value="fr">fr</option>*/}
          {/*</select>*/}
        </div>
      </div>
    </nav>
  )
};

Navbar.propTypes = {
  locale: PropTypes.string.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  locale: selectLocale,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserStatus: () => dispatch(updateUserStatus()),
  setLocale: (locale) => dispatch(setLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
