import {
  SET_LOCALE
} from './actions'
import { getLocale } from '../../utils/i18n'

// Define your state here
const initialState = {
  loading: false,
  locale: localStorage.getItem("locale") || getLocale() || "en"
};

// This export default will control your state for your application
export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
